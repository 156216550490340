.app-container {
  margin-left: 25%;
  margin-right: 25%;
}

@media only screen and (max-device-width: 720px) {
  .app-container {
    margin-left: 3%;
    margin-right: 3%;
  }
}
