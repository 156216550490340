.title-404 {
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/7/7e/Person-tree.jpg);
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 200px;
  font-size: 75px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Steelfish Rg", "helvetica neue", helvetica, arial, sans-serif;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
}
